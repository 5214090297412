#header {
    color: #262626;
    text-align: center;
    font-family: Inter;
    font-size: 5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#sub-header {
    color: #262626;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#search {
    border-radius: 48.03719rem;
    border: 0.7px solid #4A4A4A;
    /*background: rgba(0, 0, 0, 0.01);*/
}

.collection-text {
    color: #4A4A4A;
    text-align: center;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

#landing-page {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    background-image: url(../../assets/img/background.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-attachment: scroll;
    z-index: -1;
}

.nav-link {
    color: #000 !important;
    font-family: Inter !important;
    font-size: 0.85rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.nav-link.active > *, .nav-link:hover, .nav-link:hover > * {
    color: #8A40B8 !important;
    font-weight: 800 !important;
    background: transparent !important;
}
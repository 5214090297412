.root {
  display: inline-flex;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(88deg, #9e6ee6 0%, #f375f7 100%);
  position: relative;
  border-radius: 20px;
  padding: 10px 24px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 30px;
}
.english {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  white-space: pre;
  font-weight: 700;
  min-width: 65px;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
}
.englisharabicSelected {
  font-weight: 400;
}
.root:hover .english {
  font-weight: 400;
}
.rootarabicSelected:hover .englisharabicSelected {
  font-weight: 800;
}
.arabic {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  transition-property: all;
  transition-duration: 0.3s;
  min-width: 40px;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
}
.arabicarabicSelected {
  font-weight: 900;
}
.root:hover .arabic {
  font-weight: 800;
}
.rootarabicSelected:hover .arabicarabicSelected {
  font-weight: 400;
}

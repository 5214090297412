.list-image {
    position: relative;
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%) -100%;
    background-position-x: 300%;
    background-size: 300%;
    animation: shimmer 1s infinite linear;
    width: 100%;
}

@keyframes shimmer {
    to {
        background-position-x: 0;
    }
}

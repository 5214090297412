.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding-bottom: 150px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: url("./images/backgroundTwoPng.png") 0% 0% / cover;
  min-width: 0;
  padding: 0px;
}
.freeBox__xNxJ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 720px;
  background: none;
  min-width: 0;
  padding: 0px;
}
.freeBox__xNxJ > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 35px);
  height: calc(100% + 35px);
}
.freeBox__xNxJ > :global(.__wab_flex-container) > *,
.freeBox__xNxJ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xNxJ > :global(.__wab_flex-container) > picture > img,
.freeBox__xNxJ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 35px;
}
.freeBox__z2Bf1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1100px;
  min-width: 0;
  padding: 8px 24px;
}
.switchContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 57px 8px 8px;
}
.languageSwitchDiv:global(.__wab_instance) {
  position: relative;
}
.freeBox__livvq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 29px 8px 8px;
}
.text___74A0Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 5em;
  margin-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___74A0Q {
    font-size: 3em;
  }
}
.freeBox__z42J {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.freeBox__z42J > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__z42J > :global(.__wab_flex-container) > *,
.freeBox__z42J > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__z42J > :global(.__wab_flex-container) > picture > img,
.freeBox__z42J
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__z42J {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__z42J > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__z42J > :global(.__wab_flex-container) > *,
  .freeBox__z42J > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__z42J > :global(.__wab_flex-container) > picture > img,
  .freeBox__z42J
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.text__xMsSy {
  display: block;
  font-size: 5em;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
  background: linear-gradient(220deg, #fa75f8 0%, #9e6ee6 84%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  color: #53535300;
  padding-bottom: 0px;
  background-clip: text;
  -webkit-background-clip: text;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__xMsSy {
    font-size: 3em;
  }
}
.text__veGB {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 5em;
  margin-top: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__veGB {
    font-size: 3em;
  }
}
.text__bpLS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-size: 1.4em;
  margin-top: 21px;
  min-width: 0;
}
.freeBox__lzru9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: calc(47px + 35px) !important;
  min-width: 0;
  padding: 8px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__lzru9 {
    margin-top: calc(47px + 35px) !important;
  }
}
.getStartedCta2 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  top: auto;
  left: auto;
  z-index: 1;
  margin-top: 45px;
  min-width: 0;
  padding: 8px 0px;
}
.getStartedCta2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .getStartedCta2 {
    height: 100%;
    left: auto;
    top: auto;
    min-height: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .getStartedCta2 > :global(.__wab_flex-container) {
    min-height: 0;
    margin-left: calc(0px - 10px);
    width: calc(100% + 10px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .getStartedCta2 > :global(.__wab_flex-container) > *,
  .getStartedCta2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .getStartedCta2 > :global(.__wab_flex-container) > picture > img,
  .getStartedCta2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 10px;
    margin-top: 0px;
  }
}
.freeBox__gNjYe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__gNjYe > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__gNjYe {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__gNjYe > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 20px);
    height: calc(100% + 20px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__gNjYe > :global(.__wab_flex-container) > *,
  .freeBox__gNjYe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__gNjYe > :global(.__wab_flex-container) > picture > img,
  .freeBox__gNjYe
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.freeBox__bl2TU {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  max-width: 100%;
  overflow: hidden;
  min-width: 0;
  flex-shrink: 0;
}
.freeBox__bl2TU > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__bl2TU > :global(.__wab_flex-container) > *,
.freeBox__bl2TU > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bl2TU > :global(.__wab_flex-container) > picture > img,
.freeBox__bl2TU
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__bl2TU {
    height: 200px;
    overflow: auto;
    flex-shrink: 0;
  }
}
.img__yKvyl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__yKvyl > picture > img {
  object-fit: cover;
}
.img__yBlr4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__yBlr4 > picture > img {
  object-fit: cover;
}
.img___4Lxr6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___4Lxr6 > picture > img {
  object-fit: cover;
}
.img__z8Fa {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__z8Fa > picture > img {
  object-fit: cover;
}
.img__mOtt3 {
  position: relative;
  object-fit: cover;
  max-width: 400px;
  height: 100%;
}
.img__mOtt3 > picture > img {
  object-fit: cover;
}
.img__fh03H {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__fh03H > picture > img {
  object-fit: cover;
}
.img__gVr47 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__gVr47 > picture > img {
  object-fit: cover;
}
.img__fHcbx {
  position: relative;
  object-fit: cover;
  max-width: 300px;
  height: 100%;
}
.img__fHcbx > picture > img {
  object-fit: cover;
}
.img__xnm5K {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__xnm5K > picture > img {
  object-fit: cover;
}
.img__vpSLu {
  position: relative;
  object-fit: cover;
  max-width: 400px;
  height: 100%;
}
.img__vpSLu > picture > img {
  object-fit: cover;
}
.freeBox__ebPpa {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  max-width: 100%;
  overflow: hidden;
  min-width: 0;
  flex-shrink: 0;
}
.freeBox__ebPpa > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__ebPpa > :global(.__wab_flex-container) > *,
.freeBox__ebPpa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ebPpa > :global(.__wab_flex-container) > picture > img,
.freeBox__ebPpa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ebPpa {
    height: 200px;
    overflow: auto;
    flex-shrink: 0;
  }
}
.img__xa0II {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__xa0II > picture > img {
  object-fit: cover;
}
.img__obfWt {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__obfWt > picture > img {
  object-fit: cover;
}
.img__mx9Jf {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__mx9Jf > picture > img {
  object-fit: cover;
}
.img__zEXta {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__zEXta > picture > img {
  object-fit: cover;
}
.img__o4NY0 {
  position: relative;
  object-fit: cover;
  max-width: 300px;
  height: 100%;
}
.img__o4NY0 > picture > img {
  object-fit: cover;
}
.img__czxtv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__czxtv > picture > img {
  object-fit: cover;
}
.img__sfT2G {
  position: relative;
  object-fit: cover;
  max-width: 400px;
  height: 100%;
}
.img__sfT2G > picture > img {
  object-fit: cover;
}
.img__mxHdl {
  position: relative;
  object-fit: cover;
  max-width: 400px;
  height: 100%;
}
.img__mxHdl > picture > img {
  object-fit: cover;
}
.img___7G6Yb {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___7G6Yb > picture > img {
  object-fit: cover;
}
.img__nxe0V {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__nxe0V > picture > img {
  object-fit: cover;
}
.link__ftemt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(30deg, #9551fa 31%, #c775fa 100%);
  left: auto;
  top: auto;
  z-index: 1;
  margin-top: -40px;
  min-width: 300px;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 8px;
  padding: 16px 32px;
}
.root .link__ftemt:hover {
  transform: translateX(0px) translateY(-3px) translateZ(0px);
}
.text__fo8Fi {
  width: auto;
  height: auto;
  max-width: 100%;
  color: #fff8f8;
  font-size: 1.4em;
}
.columns__nJlKh {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 1100px;
  flex-direction: row;
  margin-top: 103px;
  margin-bottom: 200px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px 8px 0px;
}
.columns__nJlKh > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
  --plsmc-rc-col-gap: 40px;
}
.columns__nJlKh > :global(.__wab_flex-container) > *,
.columns__nJlKh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__nJlKh > :global(.__wab_flex-container) > picture > img,
.columns__nJlKh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
.columns__nJlKh > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__nJlKh > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__nJlKh {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__nJlKh > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__nJlKh > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__rOM {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 24px;
}
.column__rOM > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 45px);
  height: calc(100% + 45px);
}
.column__rOM > :global(.__wab_flex-container) > *,
.column__rOM > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__rOM > :global(.__wab_flex-container) > picture > img,
.column__rOM
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 45px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__rOM > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
  }
}
.frame1000003245 {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.frame1000003245 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000003245 > :global(.__wab_flex-container) > *,
.frame1000003245 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003245 > :global(.__wab_flex-container) > picture > img,
.frame1000003245
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.text__knujC {
  background: linear-gradient(
        90deg,
        rgba(77, 52, 240, 1) 0%,
        rgba(125, 86, 193, 1) 100%
      )
      0% 0% / 100% 100% no-repeat padding-box /* clip: text **/,
    rgba(0, 0, 0, 0);
  display: block;
  font-size: 2.2em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.3;
  color: transparent;
  position: relative;
  width: auto;
  background-clip: text, border-box;
  -webkit-background-clip: text, border-box;
}
.text__eFkzO {
  display: block;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  position: relative;
  width: auto;
}
.frame1000003246 {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.frame1000003246 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000003246 > :global(.__wab_flex-container) > *,
.frame1000003246 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003246 > :global(.__wab_flex-container) > picture > img,
.frame1000003246
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.text__rQhFo {
  background: linear-gradient(
        90deg,
        rgba(77, 52, 240, 1) 0%,
        rgba(125, 86, 193, 1) 100%
      )
      0% 0% / 100% 100% no-repeat padding-box /* clip: text **/,
    rgba(0, 0, 0, 0);
  display: block;
  font-size: 2.2em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.3;
  color: transparent;
  position: relative;
  width: auto;
  background-clip: text, border-box;
  -webkit-background-clip: text, border-box;
}
.text__vNo1W {
  display: block;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  position: relative;
  width: auto;
}
.frame1000003247 {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.frame1000003247 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000003247 > :global(.__wab_flex-container) > *,
.frame1000003247 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003247 > :global(.__wab_flex-container) > picture > img,
.frame1000003247
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.text__wf66W {
  background: linear-gradient(
        90deg,
        rgba(77, 52, 240, 1) 0%,
        rgba(125, 86, 193, 1) 100%
      )
      0% 0% / 100% 100% no-repeat padding-box /* clip: text **/,
    rgba(0, 0, 0, 0);
  display: block;
  font-size: 2.2em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.3;
  color: transparent;
  position: relative;
  width: auto;
  background-clip: text, border-box;
  -webkit-background-clip: text, border-box;
}
.text__r9Ddv {
  display: block;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  position: relative;
  width: auto;
}
.column__onblt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__g3Xdj {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  height: 700px;
  flex-shrink: 0;
}
.img__g3Xdj > picture > img {
  object-fit: contain;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__g3Xdj {
    height: 500px;
    flex-shrink: 0;
  }
}
.freeBox__zFqs9 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: 0px;
  background: url("./images/backgroundTwoPng.png") top 50% left 50% / cover repeat;
  min-width: 0;
}
.freeBox__ghcX1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1000px;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__qOqgp {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__tfRgY {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 890px;
  margin-top: 53px;
  min-width: 0;
  padding: 8px;
}
.figmaPaste {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-direction: column;
  min-width: 0;
}
.figmaPaste > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.figmaPaste > :global(.__wab_flex-container) > *,
.figmaPaste > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste > :global(.__wab_flex-container) > picture > img,
.figmaPaste
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.freeBox__kYjqg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  min-width: 0;
  padding: 8px;
}
.freeBox__fUtSz {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.freeBox__fUtSz > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 13px);
  width: calc(100% + 13px);
}
.freeBox__fUtSz > :global(.__wab_flex-container) > *,
.freeBox__fUtSz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fUtSz > :global(.__wab_flex-container) > picture > img,
.freeBox__fUtSz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 13px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__fUtSz {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__fUtSz > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 13px);
    height: calc(100% + 13px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__fUtSz > :global(.__wab_flex-container) > *,
  .freeBox__fUtSz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__fUtSz > :global(.__wab_flex-container) > picture > img,
  .freeBox__fUtSz
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 13px;
  }
}
.text__iMp8H {
  display: block;
  font-size: 48px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__iMp8H {
    text-align: left;
  }
}
.text___3T5L {
  font-size: 48px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
  background: linear-gradient(220deg, #fa75f8 0%, #9e6ee6 84%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  color: #53535300;
  display: none;
  background-clip: text;
  -webkit-background-clip: text;
}
.text__kSdBt {
  font-size: 48px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
  position: relative;
  left: auto;
  top: auto;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__kSdBt {
    text-align: left;
    padding: 0px 8px;
  }
}
.text__gdwzm {
  display: block;
  font-size: 1.5em;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  width: auto;
  position: relative;
  left: auto;
  top: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__gdwzm {
    text-align: left;
    padding: 0px 8px;
  }
}
.columns__wfjXr {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  margin-top: 151px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__wfjXr > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
  --plsmc-rc-col-gap: 30px;
}
.columns__wfjXr > :global(.__wab_flex-container) > *,
.columns__wfjXr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__wfjXr > :global(.__wab_flex-container) > picture > img,
.columns__wfjXr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
.columns__wfjXr > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 5 / 12);
}
.columns__wfjXr > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 7 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__wfjXr {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__wfjXr > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__wfjXr > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__dpso {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__gwtYh {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 600px;
  flex-shrink: 0;
}
.img__gwtYh > picture > img {
  object-fit: cover;
}
.column__wjYz6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 24px;
}
.column__wjYz6 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__wjYz6 > :global(.__wab_flex-container) > *,
.column__wjYz6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__wjYz6 > :global(.__wab_flex-container) > picture > img,
.column__wjYz6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__wyxcu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__wyxcu {
    align-items: flex-end;
    justify-content: flex-start;
  }
}
.freeBox__uwdSn {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
}
.freeBox__uwdSn > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 13px);
  height: calc(100% + 13px);
}
.freeBox__uwdSn > :global(.__wab_flex-container) > *,
.freeBox__uwdSn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__uwdSn > :global(.__wab_flex-container) > picture > img,
.freeBox__uwdSn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 13px;
}
.text__lfawD {
  font-size: 48px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
  background: linear-gradient(220deg, #fa75f8 0%, #9e6ee6 84%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  color: #53535300;
  display: none;
  background-clip: text;
  -webkit-background-clip: text;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__lfawD {
    text-align: left;
  }
}
.text__rkU {
  display: block;
  font-size: 48px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
  position: relative;
  left: auto;
  top: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__rkU {
    text-align: left;
    padding: 0px 8px;
  }
}
.text__bk6M {
  display: block;
  font-size: 1.5em;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__bk6M {
    padding: 0px 8px;
  }
}
.ul {
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  width: 100%;
  text-align: right;
  list-style-type: none;
  min-width: 0;
}
.li__bpXk1 {
  text-align: right;
}
.freeBox__nhZgh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1100px;
  margin-top: 149px;
  min-width: 0;
  padding: 8px;
}
.getStartedCta {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 400px;
  max-width: 100%;
  top: auto;
  left: auto;
  z-index: 1;
  margin-top: 157px;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .getStartedCta {
    height: 300px;
    left: auto;
    top: auto;
    flex-shrink: 0;
  }
}
.freeBox__r6Y9 {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 200px;
  max-width: 100%;
  left: 0;
  top: auto;
  z-index: 10;
  bottom: 0;
  right: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__r6Y9 {
    height: 140px;
  }
}
.link__stq1L {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 140px;
  max-width: 1200px;
  background: linear-gradient(90deg, #9e6ee5 20%, #d05fcf 100%) 0% 0% / 100%
    100% repeat;
  transition-property: all;
  transition-duration: 0.3s;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 8px;
  padding: 8px 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link__stq1L {
    height: 85px;
    flex-shrink: 0;
  }
}
.root .link__stq1L:hover {
  cursor: pointer;
  transform: translateX(3px) translateY(0px) translateZ(0px);
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 5em;
  color: #ffffff;
  transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
  transform-style: preserve-3d;
}
.svg * {
  transform-style: preserve-3d;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg {
    height: 2em;
    transform-style: preserve-3d;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg * {
    transform-style: preserve-3d;
  }
}
.text__r0UhH {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  font-size: 5em;
  line-height: 1;
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__r0UhH {
    font-size: 2em;
  }
}
.freeBox__k8YAu {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  max-width: 100%;
  overflow: hidden;
  min-width: 0;
}
.freeBox__k8YAu > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__k8YAu > :global(.__wab_flex-container) > *,
.freeBox__k8YAu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__k8YAu > :global(.__wab_flex-container) > picture > img,
.freeBox__k8YAu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__k8YAu {
    height: 200px;
  }
}
.img___9Remj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___9Remj > picture > img {
  object-fit: cover;
}
.img__kTnvp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__kTnvp > picture > img {
  object-fit: cover;
}
.img__zm7R7 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__zm7R7 > picture > img {
  object-fit: cover;
}
.img___1QLv9 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___1QLv9 > picture > img {
  object-fit: cover;
}
.img__fh7U9 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__fh7U9 > picture > img {
  object-fit: cover;
}
.img___0JqSi {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___0JqSi > picture > img {
  object-fit: cover;
}
.left {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
  max-width: 100%;
  top: 0px;
  z-index: 1;
  background: linear-gradient(90deg, #ffffff 7%, #ffffff00 100%) 0% 0% / 100%
    100% repeat;
  min-height: 0;
  display: none;
}
.all {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  top: 0px;
  z-index: 1;
  background: #ffffff99;
  left: -24px;
  min-height: 0;
}
.right {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
  max-width: 100%;
  top: 0px;
  z-index: 1;
  background: linear-gradient(90deg, #ffffff00 0%, #ffffff 96%) 0% 0% / 100%
    100% repeat;
  right: 0px;
  min-height: 0;
  display: none;
}
.freeBox__lKeU7 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin-top: 0px;
  min-width: 0;
  padding: 63px 8px 8px;
}
.columns__zgm2Q {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__zgm2Q > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__zgm2Q > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__zgm2Q {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__zgm2Q > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__eRe3Q {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__hX0G9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 700px;
  min-width: 0;
  padding: 24px;
}
.freeBox__hX0G9 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__hX0G9 > :global(.__wab_flex-container) > *,
.freeBox__hX0G9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hX0G9 > :global(.__wab_flex-container) > picture > img,
.freeBox__hX0G9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.text__mmMbc {
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-size: 3em;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__mmMbc {
    font-size: 2.2em;
  }
}
.text__oCdXj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: right;
  font-size: 1.4em;
  min-width: 0;
}
.column__bV1Py {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__lyKvM {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 900px;
  min-width: 0;
  padding: 8px;
}
.freeBox__gNYtM {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__gNYtM > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
}
.freeBox__gNYtM > :global(.__wab_flex-container) > *,
.freeBox__gNYtM > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gNYtM > :global(.__wab_flex-container) > picture > img,
.freeBox__gNYtM
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__gNYtM {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__gNYtM > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 30px);
    height: calc(100% + 30px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__gNYtM > :global(.__wab_flex-container) > *,
  .freeBox__gNYtM > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__gNYtM > :global(.__wab_flex-container) > picture > img,
  .freeBox__gNYtM
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 30px;
  }
}
.firstName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.lastName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__wfhnV {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__wfhnV > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox__wfhnV > :global(.__wab_flex-container) > *,
.freeBox__wfhnV > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wfhnV > :global(.__wab_flex-container) > picture > img,
.freeBox__wfhnV
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
.email:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.phone:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.companyName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.submitButton:global(.__wab_instance) {
  position: relative;
  margin-top: calc(25px + 30px) !important;
}

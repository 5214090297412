.root {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  justify-self: flex-start;
  cursor: pointer;
}
.text__r6TUw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 1.4em;
  min-width: 0;
  display: none;
}
.textloading__r6TUwyi3Hd {
  display: block;
}
.text__qZ96R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 1.4em;
  min-width: 0;
  display: none;
}
.textloading__qZ96Ryi3Hd {
  display: none;
}
.textsubmitted__qZ96RmT4Xn {
  display: block;
}
.getStartedButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(30deg, #9551fa 31%, #c775fa 100%);
  transition-property: all;
  transition-duration: 0.3s;
  min-width: 190px;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 8px;
  padding: 16px 32px;
}
.getStartedButtonloading {
  display: none;
}
.getStartedButtonsubmitted {
  display: none;
}
.root:hover .getStartedButton {
  transform: translateX(0px) translateY(-3px) translateZ(0px);
}
.text__kAE4 {
  width: auto;
  height: auto;
  max-width: 100%;
  color: #fff8f8;
  font-size: 1.4em;
}

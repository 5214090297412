.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.freeBox__erzrI {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: 0px;
  min-width: 0;
  padding: 8px;
}
.text___7C6De {
  display: block;
  font-size: 1.5em;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  width: auto;
}
.textarabic___7C6DeFxWjX {
  text-align: right;
}
.text___2L15I {
  display: block;
  font-size: 2em;
  text-align: left;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.5;
  color: rgba(61, 61, 61, 1);
  width: auto;
  margin-top: 29px;
  user-select: none;
}
.textarabic___2L15IFxWjX {
  text-align: right;
}
.freeBox__jjSkF {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 31px;
  min-width: 0;
  padding: 8px;
}
.freeBox__yEzD7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBoxarabic__yEzD7FxWjX {
  justify-content: flex-end;
}
.img {
  position: relative;
  object-fit: contain;
  max-width: 100px;
}
.img > picture > img {
  object-fit: contain;
}
.freeBox__aK0Ec {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.text__coGm3 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  font-weight: 700;
}
.text__zwhcu {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  white-space: pre-wrap;
  min-width: 290px;
  margin-top: 4px;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Montserrat:wght@300&display=swap');

body {
    margin: 0;
    font-family: 'JetBrains Mono Thin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, body {
    width: 100vw !important;
    min-height: 100dvh;
    overflow-y: auto;
    overflow-x: hidden;
    background: transparent;
}

/*#landing-page {*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    background-image: url(./assets/img/background.png);*/
/*    background-repeat: no-repeat;*/
/*    background-attachment: scroll;*/
/*    background-size: cover;*/
/*    overflow-x: hidden;*/
/*}*/

#login-page {
    width: 100vw;
    height: 100vh;
    background-image: url(./assets/img/login-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    overflow-x: hidden;
}

.mission-bg {
    background: url(./assets/img/mission-bg.png), lightgray 50% / cover no-repeat;
    background-blend-mode: lighten;
}

.mission-bg-child {
    background: linear-gradient(0deg, rgba(248, 243, 249, 0.00) 0%, #FFF 91.6%),
                linear-gradient(180deg, rgba(248, 243, 249, 0.00) 0%, #FFF 91.6%);
}

.scrollbar-hidden::-webkit-scrollbar {
    width: 0px !important;
}
*::-webkit-scrollbar {
    width: 7px;
    height: 5px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #adacac;
    background-color: #adacac;
    cursor: pointer;
}

.image-item:hover {
    background: rgba(0, 0, 0, 0.5) !important;
}

.profile-link {
    border-bottom: 3px solid transparent !important;
    color: #000 !important;
    border-radius: 0 !important;
}

.profile-link.active {
    border-bottom: 3px solid #673ab7 !important;
    color: #673ab7 !important;
    border-radius: 0 !important;
}

.nav-link.active {
    font-weight: bold !important;
    color: #673ab7 !important;
}
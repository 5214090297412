.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding-bottom: 150px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: url("./images/backgroundTwoPng.png");
  min-width: 0;
  padding: 0px;
}
.freeBox__bGv2R {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 720px;
  background: none;
  min-width: 0;
  padding: 0px;
}
.freeBox__bGv2R > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 35px);
  height: calc(100% + 35px);
}
.freeBox__bGv2R > :global(.__wab_flex-container) > *,
.freeBox__bGv2R > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bGv2R > :global(.__wab_flex-container) > picture > img,
.freeBox__bGv2R
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 35px;
}
.freeBox__ofz87 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1100px;
  min-width: 0;
  padding: 8px 24px;
}
.switchContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 57px 8px 8px;
}
.languageSwitchDiv:global(.__wab_instance) {
  position: relative;
}
.freeBox__ojvB {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 29px 8px 8px;
}
.text__hyOHr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 5em;
  margin-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__hyOHr {
    font-size: 3em;
  }
}
.freeBox__v2MwR {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.freeBox__v2MwR > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__v2MwR > :global(.__wab_flex-container) > *,
.freeBox__v2MwR > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__v2MwR > :global(.__wab_flex-container) > picture > img,
.freeBox__v2MwR
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__v2MwR {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__v2MwR > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__v2MwR > :global(.__wab_flex-container) > *,
  .freeBox__v2MwR > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__v2MwR > :global(.__wab_flex-container) > picture > img,
  .freeBox__v2MwR
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.text___5Tett {
  display: block;
  font-size: 5em;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
  background: linear-gradient(220deg, #fa75f8 0%, #9e6ee6 84%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  color: #53535300;
  padding-bottom: 0px;
  background-clip: text;
  -webkit-background-clip: text;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___5Tett {
    font-size: 3em;
  }
}
.text__uKYlo {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 5em;
  margin-top: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__uKYlo {
    font-size: 3em;
  }
}
.text__pk75 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-size: 1.4em;
  margin-top: 21px;
  min-width: 0;
}
.freeBox__sPDvb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: calc(47px + 35px) !important;
  min-width: 0;
  padding: 8px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__sPDvb {
    margin-top: calc(47px + 35px) !important;
  }
}
.getStartedCta2 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  top: auto;
  left: auto;
  z-index: 1;
  margin-top: 45px;
  min-width: 0;
  padding: 8px 0px;
}
.getStartedCta2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .getStartedCta2 {
    height: 100%;
    left: auto;
    top: auto;
    min-height: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .getStartedCta2 > :global(.__wab_flex-container) {
    min-height: 0;
    margin-left: calc(0px - 10px);
    width: calc(100% + 10px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .getStartedCta2 > :global(.__wab_flex-container) > *,
  .getStartedCta2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .getStartedCta2 > :global(.__wab_flex-container) > picture > img,
  .getStartedCta2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 10px;
    margin-top: 0px;
  }
}
.freeBox__nuO2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__nuO2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__nuO2 {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__nuO2 > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 20px);
    height: calc(100% + 20px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__nuO2 > :global(.__wab_flex-container) > *,
  .freeBox__nuO2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__nuO2 > :global(.__wab_flex-container) > picture > img,
  .freeBox__nuO2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.freeBox___0TeKe {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  max-width: 100%;
  overflow: hidden;
  min-width: 0;
  flex-shrink: 0;
}
.freeBox___0TeKe > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox___0TeKe > :global(.__wab_flex-container) > *,
.freeBox___0TeKe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0TeKe > :global(.__wab_flex-container) > picture > img,
.freeBox___0TeKe
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___0TeKe {
    height: 200px;
    overflow: auto;
    flex-shrink: 0;
  }
}
.img__aCz4G {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__aCz4G > picture > img {
  object-fit: cover;
}
.img___8YipP {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___8YipP > picture > img {
  object-fit: cover;
}
.img___0ByS {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___0ByS > picture > img {
  object-fit: cover;
}
.img__m0PwC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__m0PwC > picture > img {
  object-fit: cover;
}
.img__y6On6 {
  position: relative;
  object-fit: cover;
  max-width: 400px;
  height: 100%;
}
.img__y6On6 > picture > img {
  object-fit: cover;
}
.img___2BWj9 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___2BWj9 > picture > img {
  object-fit: cover;
}
.img__ey9Z1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__ey9Z1 > picture > img {
  object-fit: cover;
}
.img___4XCqc {
  position: relative;
  object-fit: cover;
  max-width: 300px;
  height: 100%;
}
.img___4XCqc > picture > img {
  object-fit: cover;
}
.img___13T1X {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___13T1X > picture > img {
  object-fit: cover;
}
.img__jtLN {
  position: relative;
  object-fit: cover;
  max-width: 400px;
  height: 100%;
}
.img__jtLN > picture > img {
  object-fit: cover;
}
.freeBox__uAhWa {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  max-width: 100%;
  overflow: hidden;
  min-width: 0;
  flex-shrink: 0;
}
.freeBox__uAhWa > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__uAhWa > :global(.__wab_flex-container) > *,
.freeBox__uAhWa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__uAhWa > :global(.__wab_flex-container) > picture > img,
.freeBox__uAhWa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__uAhWa {
    height: 200px;
    overflow: auto;
    flex-shrink: 0;
  }
}
.img__e9Pq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__e9Pq > picture > img {
  object-fit: cover;
}
.img__a2Lk5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__a2Lk5 > picture > img {
  object-fit: cover;
}
.img__ecQxH {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__ecQxH > picture > img {
  object-fit: cover;
}
.img__mvvo4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__mvvo4 > picture > img {
  object-fit: cover;
}
.img__b2At7 {
  position: relative;
  object-fit: cover;
  max-width: 300px;
  height: 100%;
}
.img__b2At7 > picture > img {
  object-fit: cover;
}
.img__bC5Dj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__bC5Dj > picture > img {
  object-fit: cover;
}
.img__vxsub {
  position: relative;
  object-fit: cover;
  max-width: 400px;
  height: 100%;
}
.img__vxsub > picture > img {
  object-fit: cover;
}
.img__xema1 {
  position: relative;
  object-fit: cover;
  max-width: 400px;
  height: 100%;
}
.img__xema1 > picture > img {
  object-fit: cover;
}
.img__iSHj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__iSHj > picture > img {
  object-fit: cover;
}
.img__yilV7 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__yilV7 > picture > img {
  object-fit: cover;
}
.link__pw5Pa {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(30deg, #9551fa 31%, #c775fa 100%);
  left: auto;
  top: auto;
  z-index: 1;
  margin-top: -40px;
  min-width: 300px;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 8px;
  padding: 16px 32px;
}
.root .link__pw5Pa:hover {
  transform: translateX(0px) translateY(-3px) translateZ(0px);
}
.text__fMdEz {
  width: auto;
  height: auto;
  max-width: 100%;
  color: #fff8f8;
  font-size: 1.4em;
}
.columns__qfUal {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 1100px;
  flex-direction: row;
  margin-top: 103px;
  margin-bottom: 200px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px 8px 0px;
}
.columns__qfUal > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
  --plsmc-rc-col-gap: 40px;
}
.columns__qfUal > :global(.__wab_flex-container) > *,
.columns__qfUal > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__qfUal > :global(.__wab_flex-container) > picture > img,
.columns__qfUal
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
.columns__qfUal > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__qfUal > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qfUal {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qfUal > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qfUal > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__ljFxZ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 24px;
}
.column__ljFxZ > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 45px);
  height: calc(100% + 45px);
}
.column__ljFxZ > :global(.__wab_flex-container) > *,
.column__ljFxZ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__ljFxZ > :global(.__wab_flex-container) > picture > img,
.column__ljFxZ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 45px;
}
.frame1000003245 {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.frame1000003245 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000003245 > :global(.__wab_flex-container) > *,
.frame1000003245 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003245 > :global(.__wab_flex-container) > picture > img,
.frame1000003245
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.text__rYzKy {
  background: linear-gradient(
        90deg,
        rgba(77, 52, 240, 1) 0%,
        rgba(125, 86, 193, 1) 100%
      )
      0% 0% / 100% 100% no-repeat padding-box /* clip: text **/,
    rgba(0, 0, 0, 0);
  display: block;
  font-size: 2.2em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.3;
  color: transparent;
  position: relative;
  width: auto;
  background-clip: text, border-box;
  -webkit-background-clip: text, border-box;
}
.text__rxOC {
  display: block;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  position: relative;
  width: auto;
}
.frame1000003246 {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.frame1000003246 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000003246 > :global(.__wab_flex-container) > *,
.frame1000003246 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003246 > :global(.__wab_flex-container) > picture > img,
.frame1000003246
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.text___4Gof {
  background: linear-gradient(
        90deg,
        rgba(77, 52, 240, 1) 0%,
        rgba(125, 86, 193, 1) 100%
      )
      0% 0% / 100% 100% no-repeat padding-box /* clip: text **/,
    rgba(0, 0, 0, 0);
  display: block;
  font-size: 2.2em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.3;
  color: transparent;
  position: relative;
  width: auto;
  background-clip: text, border-box;
  -webkit-background-clip: text, border-box;
}
.text__hfKX {
  display: block;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  position: relative;
  width: auto;
}
.frame1000003247 {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.frame1000003247 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000003247 > :global(.__wab_flex-container) > *,
.frame1000003247 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003247 > :global(.__wab_flex-container) > picture > img,
.frame1000003247
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.text__fQKi7 {
  background: linear-gradient(
        90deg,
        rgba(77, 52, 240, 1) 0%,
        rgba(125, 86, 193, 1) 100%
      )
      0% 0% / 100% 100% no-repeat padding-box /* clip: text **/,
    rgba(0, 0, 0, 0);
  display: block;
  font-size: 2.2em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.3;
  color: transparent;
  position: relative;
  width: auto;
  background-clip: text, border-box;
  -webkit-background-clip: text, border-box;
}
.text__vNd4B {
  display: block;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  position: relative;
  width: auto;
}
.column__lZrlj {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__viZMa {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  height: 700px;
  flex-shrink: 0;
}
.img__viZMa > picture > img {
  object-fit: contain;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__viZMa {
    height: 500px;
    flex-shrink: 0;
  }
}
.freeBox__ouF0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: 0px;
  background: url("./images/backgroundTwoPng.png") top 50% left 50% / cover repeat;
  min-width: 0;
}
.freeBox__ib5Jl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1000px;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox___09NcD {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__xLo7 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 890px;
  margin-top: 53px;
  min-width: 0;
  padding: 8px;
}
.figmaPaste {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-direction: column;
  min-width: 0;
}
.figmaPaste > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.figmaPaste > :global(.__wab_flex-container) > *,
.figmaPaste > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.figmaPaste > :global(.__wab_flex-container) > picture > img,
.figmaPaste
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.freeBox__ji2T {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  min-width: 0;
  padding: 8px;
}
.freeBox__slqY {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.freeBox__slqY > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 13px);
  width: calc(100% + 13px);
}
.freeBox__slqY > :global(.__wab_flex-container) > *,
.freeBox__slqY > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__slqY > :global(.__wab_flex-container) > picture > img,
.freeBox__slqY
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 13px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__slqY {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__slqY > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 13px);
    height: calc(100% + 13px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__slqY > :global(.__wab_flex-container) > *,
  .freeBox__slqY > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__slqY > :global(.__wab_flex-container) > picture > img,
  .freeBox__slqY
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 13px;
  }
}
.text__iWlaF {
  display: block;
  font-size: 48px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__iWlaF {
    text-align: left;
  }
}
.text__orf96 {
  display: block;
  font-size: 48px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
  background: linear-gradient(220deg, #fa75f8 0%, #9e6ee6 84%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  color: #53535300;
  background-clip: text;
  -webkit-background-clip: text;
}
.text__sq8Gh {
  display: block;
  font-size: 48px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
  position: relative;
  left: auto;
  top: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__sq8Gh {
    text-align: left;
    padding: 0px 8px;
  }
}
.text___3Hxhq {
  display: block;
  font-size: 1.5em;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  width: auto;
  position: relative;
  left: auto;
  top: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___3Hxhq {
    text-align: left;
    padding: 0px 8px;
  }
}
.columns__heJIg {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  margin-top: 151px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__heJIg > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
  --plsmc-rc-col-gap: 30px;
}
.columns__heJIg > :global(.__wab_flex-container) > *,
.columns__heJIg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__heJIg > :global(.__wab_flex-container) > picture > img,
.columns__heJIg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
.columns__heJIg > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 5 / 12);
}
.columns__heJIg > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 7 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__heJIg {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__heJIg > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__heJIg > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__mhpid {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__iw7FN {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 600px;
  flex-shrink: 0;
}
.img__iw7FN > picture > img {
  object-fit: cover;
}
.column__xpZg8 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 24px;
}
.column__xpZg8 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__xpZg8 > :global(.__wab_flex-container) > *,
.column__xpZg8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__xpZg8 > :global(.__wab_flex-container) > picture > img,
.column__xpZg8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox___3Nfax {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  min-width: 0;
}
.freeBox__ge7Li {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
}
.freeBox__ge7Li > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 13px);
  height: calc(100% + 13px);
}
.freeBox__ge7Li > :global(.__wab_flex-container) > *,
.freeBox__ge7Li > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ge7Li > :global(.__wab_flex-container) > picture > img,
.freeBox__ge7Li
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 13px;
}
.text___5Qf9P {
  display: block;
  font-size: 48px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
  background: linear-gradient(220deg, #fa75f8 0%, #9e6ee6 84%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  color: #53535300;
  background-clip: text;
  -webkit-background-clip: text;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___5Qf9P {
    text-align: left;
  }
}
.text__tT2Ju {
  display: block;
  font-size: 48px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 64px;
  width: auto;
  position: relative;
  left: auto;
  top: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__tT2Ju {
    text-align: left;
    padding: 0px 8px;
  }
}
.text__zKy27 {
  display: block;
  font-size: 1.5em;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 40px;
  color: rgba(61, 61, 61, 1);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__zKy27 {
    padding: 0px 8px;
  }
}
.ul {
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  width: 100%;
  min-width: 0;
}
.freeBox__i05DO {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1100px;
  margin-top: 149px;
  min-width: 0;
  padding: 8px;
}
.getStartedCta {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 400px;
  max-width: 100%;
  top: auto;
  left: auto;
  z-index: 1;
  margin-top: 157px;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .getStartedCta {
    height: 300px;
    left: auto;
    top: auto;
    flex-shrink: 0;
  }
}
.freeBox__gbSC {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 200px;
  max-width: 100%;
  left: 0;
  top: auto;
  z-index: 10;
  bottom: 0;
  right: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__gbSC {
    height: 140px;
  }
}
.link__r0JI {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 140px;
  max-width: 1200px;
  background: linear-gradient(90deg, #9e6ee5 20%, #d05fcf 100%) 0% 0% / 100%
    100% repeat;
  transition-property: all;
  transition-duration: 0.3s;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 8px;
  padding: 8px 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link__r0JI {
    height: 85px;
    flex-shrink: 0;
  }
}
.root .link__r0JI:hover {
  cursor: pointer;
  transform: translateX(3px) translateY(0px) translateZ(0px);
}
.text__cmAmq {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  font-size: 5em;
  line-height: 1;
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__cmAmq {
    font-size: 2em;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 5em;
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg {
    height: 2em;
  }
}
.freeBox__ttI3Y {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  max-width: 100%;
  overflow: hidden;
  min-width: 0;
}
.freeBox__ttI3Y > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__ttI3Y > :global(.__wab_flex-container) > *,
.freeBox__ttI3Y > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ttI3Y > :global(.__wab_flex-container) > picture > img,
.freeBox__ttI3Y
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ttI3Y {
    height: 200px;
  }
}
.img__r56P {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__r56P > picture > img {
  object-fit: cover;
}
.img___2Nhf {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___2Nhf > picture > img {
  object-fit: cover;
}
.img___4GLjO {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img___4GLjO > picture > img {
  object-fit: cover;
}
.img__th3P5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__th3P5 > picture > img {
  object-fit: cover;
}
.img__ck3Pr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__ck3Pr > picture > img {
  object-fit: cover;
}
.img__n4BIi {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.img__n4BIi > picture > img {
  object-fit: cover;
}
.left {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
  max-width: 100%;
  top: 0px;
  z-index: 1;
  background: linear-gradient(90deg, #ffffff 7%, #ffffff00 100%) 0% 0% / 100%
    100% repeat;
  min-height: 0;
  display: none;
}
.all {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  top: 0px;
  z-index: 1;
  background: #ffffff99;
  left: -24px;
  min-height: 0;
}
.right {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
  max-width: 100%;
  top: 0px;
  z-index: 1;
  background: linear-gradient(90deg, #ffffff00 0%, #ffffff 96%) 0% 0% / 100%
    100% repeat;
  right: 0px;
  min-height: 0;
  display: none;
}
.freeBox__giou8 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin-top: 0px;
  min-width: 0;
  padding: 63px 8px 8px;
}
.columns__pDggG {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__pDggG > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__pDggG > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__pDggG {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__pDggG > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__j6LEr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__rs9Oc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 700px;
  min-width: 0;
  padding: 24px;
}
.freeBox__rs9Oc > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__rs9Oc > :global(.__wab_flex-container) > *,
.freeBox__rs9Oc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rs9Oc > :global(.__wab_flex-container) > picture > img,
.freeBox__rs9Oc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.text__dTa8Z {
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 3em;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__dTa8Z {
    font-size: 2.2em;
  }
}
.text__kj1Bg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: left;
  font-size: 1.4em;
  min-width: 0;
}
.column__o90T {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__gTRrf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 900px;
  min-width: 0;
  padding: 8px;
}
.freeBox__i0GG {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__i0GG > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
}
.freeBox__i0GG > :global(.__wab_flex-container) > *,
.freeBox__i0GG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__i0GG > :global(.__wab_flex-container) > picture > img,
.freeBox__i0GG
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__i0GG {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__i0GG > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 30px);
    height: calc(100% + 30px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__i0GG > :global(.__wab_flex-container) > *,
  .freeBox__i0GG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__i0GG > :global(.__wab_flex-container) > picture > img,
  .freeBox__i0GG
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 30px;
  }
}
.firstName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.lastName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___9GGKa {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___9GGKa > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox___9GGKa > :global(.__wab_flex-container) > *,
.freeBox___9GGKa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9GGKa > :global(.__wab_flex-container) > picture > img,
.freeBox___9GGKa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
.email:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.phone:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.companyName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.submitButton:global(.__wab_instance) {
  position: relative;
  margin-top: calc(25px + 30px) !important;
}
